import { useTranslation } from 'react-i18next';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';

import { TextInput } from '@library/components';
import { AddCustomersModal } from '@components/Customers/AddCustomersModal/AddCustomersModal';

import { useAuth } from '@/context-providers/auth';

interface CustomersListHeaderProp {
  filterText: string;
  filterCustomers: (searchText) => void;
  disableFilter: boolean;
}

const CustomersListHeader = ({
  filterText,
  filterCustomers,
  disableFilter
}: CustomersListHeaderProp) => {
  const [t] = useTranslation();
  const { isWriteUser } = useAuth();

  const textInputHandler = (searchText) => {
    filterCustomers(searchText);
  };

  return (
    <div className='mb-10 flex  items-center justify-between'>
      <div className='basis-2/5'>
        <TextInput
          value={filterText}
          className='flex-1'
          onChange={textInputHandler}
          decorator={<MagnifyingGlassIcon />}
          size='size.md'
          placeholder={`${t('label.searchCustomer')}`}
          disabled={disableFilter}
        />
      </div>
      {isWriteUser ? (
        <div className='flex justify-end'>
          <AddCustomersModal />
        </div>
      ) : null}
    </div>
  );
};

export { CustomersListHeader };
