import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ShoppingCartIcon } from '@heroicons/react/24/solid';

import { AddOffersModal } from '../AddOffersModal/AddOffersModal';
import { OfferListRow } from './OfferListRow';
import { OfferInstructionsModal } from '@/components/Instructions/OfferInstructionsModal';
import { AddOfferRowLoading } from '@components/Offers/AddOffersModal/AddOffersModalContent';

import { useAuth } from '@/context-providers/auth';

import { Offer } from '@models/campaign';
import { ServerSideTable, tableColumnDefs } from '@library/components';

interface OffersListProps {
  offers: Offer[];
  isLoading: boolean;
  isError: boolean;
  isCustomersListEmpty: boolean;
  totalOffers: number;
  pagination: {
    pageIndex: number;
    pageSize: number;
  };
  onPaginationChange: (arg) => void;
}

const EmptyOffersList = ({ isLoading }: { isLoading: boolean }) => {
  const [t] = useTranslation();

  return isLoading ? null : (
    <div className='mt-20 flex items-center justify-center'>
      <ShoppingCartIcon className='mr-6 h-40 w-40 text-gray-disabledText' />
      <div>
        <h3 className='text-xl font-bold'>{t('label.noOffersSelected')}</h3>
        <p>{t('label.addOfferToAssign')}</p>
      </div>
    </div>
  );
};

const OffersList = ({
  offers,
  isLoading,
  isError,
  isCustomersListEmpty,
  totalOffers,
  pagination,
  onPaginationChange
}: OffersListProps) => {
  const [t] = useTranslation();

  const { isWriteUser } = useAuth();

  const filteredOffers = useMemo(
    () => offers.filter((offer) => !offer.isDeleted),
    [offers]
  );

  const isOffersListEmpty = useMemo(
    () => filteredOffers.length === 0,
    [filteredOffers]
  );

  const columns = [
    tableColumnDefs.custom<Offer>({
      header: '',
      accessorKey: 'sourceCustomerId',
      enableSorting: false,
      cell({ row }) {
        return (
          <>
            <OfferListRow {...row.original} />
          </>
        );
      }
    })
  ];

  return (
    <div>
      <div className='mb-2 flex flex-row items-center justify-between'>
        <div className='flex flex-col'>
          <h2 className='mb-2 text-2xl font-bold'>
            {t('label.offersHeading')}
          </h2>
          <p className='text-md'>{t('label.offersSubheading')}</p>
        </div>
        <div className='self-center'>
          {isWriteUser && (
            <AddOffersModal isCustomersListEmpty={isCustomersListEmpty} />
          )}
        </div>
      </div>
      {isLoading && isOffersListEmpty && (
        <div className='px-6'>
          <AddOfferRowLoading />
          <AddOfferRowLoading />
          <AddOfferRowLoading />
          <AddOfferRowLoading />
        </div>
      )}
      {isOffersListEmpty && !isError ? (
        <EmptyOffersList isLoading={isLoading} />
      ) : (
        <div>
          {isError ? (
            <h2 className='mt-10 w-256 text-lg font-bold'>
              {t('label.couldNotLoadOffers')}
            </h2>
          ) : (
            <div>
              <ServerSideTable
                id='offer-list'
                borderless={false}
                data={filteredOffers || []}
                loading={isLoading}
                columns={columns}
                totalRows={totalOffers}
                pagination={pagination}
                onPaginationChange={onPaginationChange}
                pageSizeOptions={[5, 10]}
                noDataMessage='no offers'
              />
            </div>
          )}
        </div>
      )}

      <OfferInstructionsModal autoTrigger={true} />
    </div>
  );
};

export { OffersList, EmptyOffersList };
