import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { PlusIcon, MinusIcon } from '@heroicons/react/24/solid';

import { InfoContainer } from '@/components/InfoContainer/InfoContainer';
import { PropertyInfo } from '@/components/PropertyInfo/PropertyInfo';
import {
  HorizontalLoader,
  LinkButton,
  PrimaryButton,
  SecondaryButton,
  SingleCheckbox,
  Tooltip
} from '@/library/components';
import { EditCustomerOffersList } from './EditCustomerOffersList';
import { NumberInput } from '@library/components/inputs';

import { useInstitution } from '@/context-providers/institution';
import { useCustomer } from '@/context-providers/customer';
import { useAuth } from '@/context-providers/auth';

import { Offer } from '@models/campaign';

import { classnames } from '@library/utils';
import { RedeemedOffersList } from './RedeemedOffersList ';

interface EditCustomerModalContentProps {
  offers: Offer[];
  isLoadingOffer: boolean;
  isErrorOffer: boolean;
}

const CustomerProfile = () => {
  const [isCustomerDetailsExpanded, setIsCustomerDetailsExpanded] =
    useState(false);

  const [t] = useTranslation();
  const { currency } = useInstitution();
  const { isWriteUser } = useAuth();

  const {
    isLoading,
    optedInValue,
    earnedAmount,
    isSavingProfile,
    isError,
    customer,
    setEarnedAmount,
    setOptedInValue,
    saveCustomerProfile
  } = useCustomer();

  useEffect(() => {
    setEarnedAmount(customer.lifetimeEarnedAmount as number);
    setOptedInValue(customer.enrollmentOptInStatus === 'ACTIVE');
  }, [customer]);

  const {
    sourceCustomerId,
    sourceAccountId,
    sourceOrganizationId,
    sourcePortfolioId,
    createDate
  } = customer;

  const numberInputHandler = (value) => {
    setEarnedAmount(value);
  };

  return isLoading ? (
    <div className='w-full'>
      <div className='pulsate mb-2 h-32 w-full animate-pulsate' />
    </div>
  ) : (
    <InfoContainer>
      <div className='flex items-start justify-between'>
        <h3
          className={classnames(
            'text-xl font-bold',
            isCustomerDetailsExpanded ? 'mb-6' : 'mb-2'
          )}
        >
          {t('label.customerProfile')}
        </h3>
        <LinkButton
          onClick={() =>
            setIsCustomerDetailsExpanded(!isCustomerDetailsExpanded)
          }
          size='size.md'
        >
          {isCustomerDetailsExpanded ? (
            <MinusIcon className='mr-2 h-4 w-4' />
          ) : (
            <PlusIcon className='mr-2 h-4 w-4' />
          )}
          {isCustomerDetailsExpanded
            ? t('label.hideDetails')
            : t('label.showDetails')}
        </LinkButton>
      </div>
      {isError ? (
        <h2 className='text-lg font-bold'>
          {t('label.couldNotLoadCustomers')}
        </h2>
      ) : (
        <div>
          <div>
            <PropertyInfo
              text={t('label.sourceCustomerId')}
              value={sourceCustomerId}
            />
          </div>
          {isCustomerDetailsExpanded && (
            <div className='CustomerProfile__accordion-container mt-4'>
              <div className='grid grid-cols-2 gap-4'>
                <PropertyInfo
                  text={t('label.sourceAccountId')}
                  value={sourceAccountId}
                />
                <PropertyInfo
                  text={t('label.organization')}
                  value={sourceOrganizationId}
                />
                <PropertyInfo
                  text={t('label.portfolio')}
                  value={sourcePortfolioId}
                />
                <PropertyInfo
                  text={t('label.createDate')}
                  value={createDate ? createDate.split('T')[0] : ''}
                />
              </div>
              <div>
                <div className='mt-4 grid grid-cols-2 gap-4'>
                  <div className='my-4 flex basis-1/2 flex-col justify-around'>
                    <div className='mb-1 text-sm font-bold'>
                      {t('label.lifetimeEarned')}
                    </div>
                    {isLoading ? (
                      <div className='pulsate mb-2 h-8 w-3/5 animate-pulsate' />
                    ) : (
                      <NumberInput
                        className='flex-1'
                        onChange={numberInputHandler}
                        size='size.md'
                        value={earnedAmount}
                        currency={currency.code}
                        disabled={isLoading || !isWriteUser}
                      />
                    )}
                  </div>
                  <div className='mt-4'>
                    <h3 className='text-md mb-1 text-sm font-bold'>
                      {t('label.enrollmentStatus')}
                    </h3>
                    {isLoading ? (
                      <div className='pulsate mb-2 h-8 w-3/5 animate-pulsate' />
                    ) : (
                      <div className='mt-2'>
                        <SingleCheckbox
                          id='enrollment'
                          checked={optedInValue}
                          onSelectedItemChange={setOptedInValue}
                          disabled={isLoading || !isWriteUser}
                        >
                          {t('label.optedIn')}
                        </SingleCheckbox>
                      </div>
                    )}
                  </div>
                </div>
                {isWriteUser ? (
                  <div>
                    <Tooltip content={t('library.saveChanges')}>
                      <div className='relative'>
                        <PrimaryButton
                          size='size.md'
                          onClick={saveCustomerProfile}
                          disabled={isSavingProfile || isLoading}
                        >
                          {isSavingProfile
                            ? t('common.savingEllipsis')
                            : t('library.saveChanges')}
                        </PrimaryButton>
                        <HorizontalLoader isActive={isSavingProfile} />
                      </div>
                    </Tooltip>
                  </div>
                ) : null}
              </div>
            </div>
          )}
        </div>
      )}
    </InfoContainer>
  );
};

const EditCustomerModalContent = ({
  offers,
  isLoadingOffer,
  isErrorOffer
}: EditCustomerModalContentProps) => {
  const [t] = useTranslation();
  const { customer, setIsEditCustomerModalOpen } = useCustomer();

  const { sourceCustomerId } = customer;

  return (
    <div className='h-512 w-256 overflow-visible p-4 pb-0'>
      <CustomerProfile />

      <div className='mx-6 my-4 hidden py-4'>
        <RedeemedOffersList
          offers={offers}
          isLoading={isLoadingOffer}
          isError={isErrorOffer}
        />
      </div>

      <div className='mx-6 my-4 py-4'>
        <EditCustomerOffersList
          offers={offers}
          isLoading={isLoadingOffer}
          isError={isErrorOffer}
          customerId={sourceCustomerId}
        />
      </div>

      <div className='mt-8 flex flex-row justify-between'>
        <Tooltip content={t('library.cancel')}>
          <SecondaryButton
            size='size.md'
            onClick={() => setIsEditCustomerModalOpen(false)}
          >
            {t('library.cancel')}
          </SecondaryButton>
        </Tooltip>
      </div>
    </div>
  );
};

export { EditCustomerModalContent };
