import invariant from 'tiny-invariant';
import { ServiceData, ServiceQuery } from '../service';

import { customerAdapter } from '@adapters/customer';

import { SingleCustomerResponse, Customer } from '@models/customer';

interface GetByIdData extends ServiceData {
  customer: Customer;
}

interface GetByIdParams {
  sourceCustomerId: string;
}

const parseCustomerResponse = (customer: SingleCustomerResponse): Customer => {
  const {
    sourceCustomerId,
    organizationId,
    customerCreationDate,
    enrollmentOptInStatus,
    accounts,
    portfolios,
    lifetimeEarnedAmount
  } = customer;

  const iin =
    accounts.length > 0 && accounts[0].cards.length > 0
      ? accounts[0].cards[0].iin
      : 'Error IIN';

  const last4 =
    accounts.length > 0 && accounts[0].cards.length > 0
      ? accounts[0].cards[0].last4
      : 'Error IIN';

  return {
    sourceCustomerId,
    sourceOrganizationId: organizationId,
    createDate: customerCreationDate,
    enrollmentOptInStatus,
    lifetimeEarnedAmount,
    sourceAccountId:
      accounts.length > 0 ? accounts[0].sourceAccountId : 'Error Account ID',
    sourcePortfolioId:
      portfolios.length > 0
        ? portfolios.map((portfolio) => portfolio.sourcePortfolioId).join(', ')
        : 'NO PORTFOLIO ID',
    iin,
    last4
  };
};

const getById: ServiceQuery<GetByIdData, GetByIdParams> = async (context) => {
  const { queryKey, signal } = context;
  const [{ sourceCustomerId }] = queryKey;

  invariant(sourceCustomerId, 'sourceCustomerId is required');

  const { data } = await customerAdapter.getById(
    { sourceCustomerId },
    { signal }
  );

  return { customer: parseCustomerResponse(data) };
};

export type { GetByIdData, GetByIdParams };
export { getById };
