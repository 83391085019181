import { apiTransport } from '@services/rest-api';
import { AdapterFunction } from '../types';
import { GetCustomerAdRedemptionsResponse } from '@models/customer';

interface GetByIdParams {
  sourceCustomerId: string;
}

const getRedemptions: AdapterFunction<
  GetCustomerAdRedemptionsResponse,
  GetByIdParams
> = ({ sourceCustomerId }, { signal }) => {
  const url = `/v1/customers/redemptions`;

  apiTransport.setHeader({
    key: 'X-Cdlx-Customer-Id',
    value: sourceCustomerId
  });

  const response = apiTransport.get<GetCustomerAdRedemptionsResponse>({
    url,
    config: {
      signal
    }
  });

  apiTransport.setHeader({ key: 'X-Cdlx-Customer-Id', value: '' });

  return response;
};

export type { GetByIdParams };
export { getRedemptions };
