import {
  ServiceOptions,
  wrapMutationFunction,
  wrapQueryFunction
} from '../service';

import { create } from './create';
import { getById } from './get-by-id';
import { getOfferTransactions } from './get-offer-transactions';
import { getOffersById } from './get-offers-by-id';
import { del } from './delete';
import { postClientEvent } from './post-click-event';
import { update } from './update';
import { createOfferTransaction } from './create-offer-transaction';
import { get } from './get';
import { getRedemptions } from './get-redemptions';

const options: ServiceOptions = {
  name: 'CUSTOMER',
  cacheTime: 'cache.long'
};

const customerService = {
  invalidationKey: [{ name: options.name }],
  create: wrapMutationFunction(options, create),
  get: wrapQueryFunction(options, get),
  getById: wrapQueryFunction(options, getById),
  getRedemptions: wrapQueryFunction(options, getRedemptions),
  getOffersById: wrapQueryFunction(options, getOffersById),
  delete: wrapMutationFunction(options, del),
  postClientEvent: wrapMutationFunction(options, postClientEvent),
  getOfferTransactions: wrapQueryFunction(options, getOfferTransactions),
  update: wrapMutationFunction(options, update),
  createOfferTransaction: wrapMutationFunction(options, createOfferTransaction)
};

export { customerService };
