const getRewardTypeValue = (
  rewardType: string,
  isMultiRedemption?: boolean
) => {
  switch (rewardType) {
    case 'PERCENT_AMOUNT_PURCHASE': {
      return isMultiRedemption
        ? 'TYPE_MULTIPLE_REWARD_PERCENT_AMOUNT_BACK'
        : 'TYPE_SINGLE_REWARD_PERCENT_AMOUNT_BACK';
    }
    case 'PercentBack': {
      return isMultiRedemption
        ? 'TYPE_MULTIPLE_REWARD_PERCENT_AMOUNT_BACK'
        : 'TYPE_SINGLE_REWARD_PERCENT_AMOUNT_BACK';
    }
    case 'FlatAmountBack': {
      return 'TYPE_SINGLE_REWARD_FLAT_AMOUNT_BACK';
    }
    case 'FIXED_AMOUNT_PURCHASE': {
      return 'TYPE_SINGLE_REWARD_FLAT_AMOUNT_BACK';
    }
    default: {
      return 'undefined';
    }
  }
};

export { getRewardTypeValue };
