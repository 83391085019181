import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckCircleIcon } from '@heroicons/react/24/solid';

import {
  Dropdown,
  DropdownItem,
  HorizontalLoader,
  PrimaryButton,
  SecondaryButton,
  SingleCheckbox
} from '@library/components';
import { OfferHeadline } from '@components/Offers/OfferHeadline';
import { OfferDetails } from '@components/Offers/OfferDetails';

import { useOffer } from '@/context-providers/offer';
import { useCustomer } from '@/context-providers/customer';
import { useInstitution } from '@/context-providers/institution';
import { useAuth } from '@/context-providers/auth';

import { RedemptionForm } from './RedemptionForm';

const SingleOffer = ({ originalOffer }: any) => {
  const [t] = useTranslation();
  const { currency } = useInstitution();
  const { isWriteUser } = useAuth();
  const {
    advertisement,
    isSavingOfferState,
    selectedOfferState,
    offerStateOptions,
    isRedeemFieldsVisible,
    isTransactionStringsEmpty,
    pendingTransactions,
    isRedeemingOffer,
    parkedOffer,
    isOfferHidden,
    isLoadingTransactionsStrings,
    transactionValidationMessages,
    removeAllPendingTransactions,
    setIsRedeemFieldsVisible,
    handleRedeemOffer,
    saveOfferState,
    setAdvertisement,
    setSelectedOfferState,
    handlePostRedemptions,
    handleParkOffer
  } = useOffer();

  const { isRefetchingOffers, customerRedemptions, isErrorRedemptions } =
    useCustomer();

  useEffect(() => {
    setAdvertisement(originalOffer);
  }, []);

  const activationState = useMemo(
    () => advertisement.activationState,
    [advertisement]
  );

  const redemptionState = useMemo(
    () => advertisement.redemptionState,
    [advertisement]
  );

  const offerRedemptions = useMemo(
    () =>
      customerRedemptions.length > 0 && advertisement?.id
        ? customerRedemptions.filter(
            (redemption) => redemption.adId === advertisement.id
          )
        : [],
    [customerRedemptions, advertisement]
  );

  const headlineProps = useMemo(
    () =>
      advertisement.merchantName && {
        logo: advertisement.assets.logo.value.small.url,
        merchantName: advertisement.merchantName,
        rewardDetails: advertisement.assets.copy.value.rewardCopy,
        startDate: advertisement.startDate,
        endDate: advertisement.endDate,
        offerId: advertisement.id,
        purchaseChannels:
          advertisement.reward.purchaseRequirement.purchaseChannels
      },
    [advertisement]
  );

  const detailsProps = useMemo(() => {
    if (!advertisement.merchantName) {
      return false;
    }

    const {
      rewardType,
      rewardAmount,
      isMultiRedemption: isMultipleRedemption
    } = advertisement.reward;
    return {
      rewardAmount: rewardType === 'FIXED_AMOUNT_PURCHASE' ? rewardAmount : 0,
      rewardPercent:
        rewardType === 'PERCENT_AMOUNT_PURCHASE' ? rewardAmount : 0,
      rewardType,
      endDate: advertisement.endDate,
      activationDate: advertisement.activationDate,
      isMultiRedemption: isMultipleRedemption
    };
  }, [advertisement]);

  const isMultiRedemption = useMemo(
    () => advertisement?.reward?.isMultiRedemption,
    [advertisement]
  );

  const handleOfferStateChange = (item) => {
    setSelectedOfferState(item);
    saveOfferState(item);
  };

  const handleParkOfferChange = (value: boolean) => {
    handleParkOffer(value);
  };

  const handleCancelRedemption = () => {
    setIsRedeemFieldsVisible(false);
    removeAllPendingTransactions();
  };

  return (
    <div className='SingleOffer pr-2'>
      <div className='my-2 flex flex-col py-2'>
        <div className='flex flex-row justify-between pb-6'>
          <div className='flex w-[380px] grow flex-col'>
            {headlineProps && <OfferHeadline {...headlineProps} />}
          </div>
          <div className='basis-1/3'>
            {detailsProps && <OfferDetails {...detailsProps} />}
          </div>
          <div className='flex w-[180px] flex-row items-start justify-center'>
            {redemptionState && redemptionState === 'FULLY_REDEEMED' ? (
              <div>
                <div className='flex justify-center'>
                  <CheckCircleIcon className='my-2 h-10 w-10 text-green-success' />
                </div>
                <h2 className='text-lg font-bold'>
                  {t('label.fullyRedeemed')}
                </h2>
              </div>
            ) : (
              <div className='flex w-44 flex-col justify-end'>
                <p>{t('label.setOfferState')}</p>
                <div className='relative w-full' key={`${isRefetchingOffers}`}>
                  <Dropdown
                    selectedItem={selectedOfferState}
                    onSelectionChange={handleOfferStateChange}
                    itemKey='id'
                    items={offerStateOptions}
                    size='size.md'
                    aria-label={`${t('label.aria')}`}
                    className='dropdown-class text-sm'
                    disabled={
                      activationState === 'ACTIVATED' ||
                      isRefetchingOffers ||
                      isSavingOfferState ||
                      !isWriteUser
                    }
                    forceIsOpen={false}
                  >
                    {(item) => <DropdownItem>{item.label}</DropdownItem>}
                  </Dropdown>
                  <HorizontalLoader isActive={isSavingOfferState} />
                </div>
                <div className='mt-2 self-start'>
                  <SingleCheckbox
                    id='offerVisibility'
                    checked={parkedOffer}
                    onSelectedItemChange={handleParkOfferChange}
                    disabled={isSavingOfferState || !isWriteUser}
                  >
                    <p className='text-sm font-bold'>{t('label.parked')}</p>
                  </SingleCheckbox>
                </div>
                {activationState === 'ACTIVATED' && (
                  <div className='mt-2'>
                    <div className='relative'>
                      <PrimaryButton
                        onClick={handleRedeemOffer}
                        size='size.md'
                        width='width.full'
                        disabled={
                          (isRedeemFieldsVisible &&
                            isTransactionStringsEmpty) ||
                          isOfferHidden ||
                          !isWriteUser
                        }
                      >
                        <div className='w-32'>{t('label.redeem')}</div>
                      </PrimaryButton>
                      {advertisement &&
                        advertisement.transactions &&
                        advertisement.transactions.length > 0 && (
                          <p className='mt-1 text-sm font-bold'>{`${advertisement.transactions.length} transaction(s) submitted`}</p>
                        )}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {isErrorRedemptions && (
          <div className='pb-4 pl-10'>
            <h2 className='-ml-6 w-256 text-lg font-bold'>
              {t('common.couldNotLoadRedemptions')}
            </h2>
          </div>
        )}
        {offerRedemptions.length > 0 && (
          <div className='OfferRedemption'>
            {offerRedemptions.map((redemption) => (
              <div
                key={redemption.redemptionId}
                className='flex items-center justify-start pb-4 pl-10'
              >
                <div className='w-[60px]'>
                  <CheckCircleIcon className='mx-4 h-7 w-7 text-green-success' />
                </div>
                <div className='w-[120px] text-sm'>
                  <h3 className='font-bold'>Date</h3>
                  {redemption.redemptionDate.split('T')[0]}
                </div>
                <div className='w-[200px] text-sm'>
                  <h3 className='font-bold'>{t('label.transactionAmount')}</h3>
                  {`${currency.mark}${redemption.redeemingTransactionAmount}`}
                </div>
                <div className='w-[200px] text-sm'>
                  <h3 className='font-bold'>{t('label.rewardAmount')}</h3>
                  {`${currency.mark}${redemption.amount}`}
                </div>
              </div>
            ))}
          </div>
        )}

        {isRedeemFieldsVisible && (
          <div>
            {pendingTransactions.map((transaction, index) => (
              <div key={transaction.localId}>
                <RedemptionForm order={index} transaction={transaction} />
              </div>
            ))}

            {transactionValidationMessages.length > 0 && (
              <div className='mt-3 flex justify-end'>
                <ul>
                  {transactionValidationMessages.map((message) => (
                    <li key={message} className='text-right text-xs text-error'>
                      {message}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <div className='mt-3 flex flex-row justify-between'>
              <SecondaryButton onClick={handleCancelRedemption}>
                {t('library.cancel')}
              </SecondaryButton>
              <div className='flex items-center justify-end'>
                {isMultiRedemption && (
                  <div className='mr-6'>
                    <SecondaryButton onClick={handleRedeemOffer}>
                      {t('label.addAnother')}
                    </SecondaryButton>
                  </div>
                )}
                <div className='relative'>
                  <PrimaryButton
                    onClick={handlePostRedemptions}
                    disabled={
                      isTransactionStringsEmpty ||
                      isRedeemingOffer ||
                      isLoadingTransactionsStrings
                    }
                  >
                    {isRedeemingOffer ? 'Redeeming' : t('label.postRedemption')}
                  </PrimaryButton>
                  <HorizontalLoader isActive={isRedeemingOffer} />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export { SingleOffer };
