import { useTranslation } from 'react-i18next';

import './OfferConditions.css';

interface OfferConditionsProps {
  startDate: string;
  endDate: string;
}

const OfferConditions = ({ startDate, endDate }: OfferConditionsProps) => {
  const [t] = useTranslation();

  return (
    <div className='OfferConditions ml-10 flex w-[190px] shrink flex-col pt-2'>
      <div className='mb-2 text-sm'>
        {t('label.startOnDate', { date: `${startDate.split('T')[0]}` })}
      </div>
      <div className='text-sm'>
        {t('label.endOnDate', { date: `${endDate.split('T')[0]}` })}
      </div>
    </div>
  );
};

export { OfferConditions };
