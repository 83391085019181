import { TransactionType } from './customer';

export interface Offer {
  accountId: string;
  adId: number;
  campaignId: number;
  startDate: string;
  endDate: string;
  logo: string;
  merchantName: string;
  name: string;
  rewardAmount: number;
  rewardDetails: string;
  rewardPercent: number;
  rewardType: string;
  isDeleted: boolean;
  redemptionState: AdRedemptionState;
  transaction?: Transaction[];
  isMultiRedemption?: boolean;
}

export interface Transaction {
  adId: number;
  amount: string;
  customerId: number;
  institutionId: number;
  sourceCustomerId: string;
  transactionId: string;
  transactionPostingTimestamp: string;
  transactionTimestamp: string;
  transactionType: TransactionType;
}

type AdRedemptionState = 'PROCESSING' | 'FULLY_REDEEMED';

export interface GetCampaignByIdResponse {
  accountId: string;
  ads: Ad[];
  campaignId: number;
  endDate: string;
  startDate: string;
  name: string;
}

export interface Ad {
  adId: number;
  creative: Creative;
  expiresMessage: string;
  minSpend: number;
  minVisits: number;
  productCategoryAnnotations: string[];
  rewardAmount: number;
  rewardAnnotations: string[];
  rewardCap: number;
  rewardCapMessage: string;
  rewardPercent: number;
  rewardType: string;
  storeLocationTypes: string[];
}

export interface Creative {
  adCopy: AdCopy[];
  id: number;
  images: Image[];
  links: Link[];
  merchantName: string;
}

export interface AdCopy {
  adCopyId?: number;
  id: number;
  slot: string;
  text: string;
}

export interface Image {
  externalUrl: string;
  id: number;
  imageId?: number;
  slot: string;
}

export interface Link {
  id: number;
  linkId?: number;
  slot: string;
  text: string;
  url: string;
}

export interface CreateOfferRequest {
  accountId: string;
  campaignIds: number[];
  customerIds: number[];
}

export interface UpdateCampaignRequest {
  accountId: string;
  campaignId: number;
  endDate: Date;
}

export const RewardModelType = {
  SingleRewardPercentAmountBack: 'TYPE_SINGLE_REWARD_PERCENT_AMOUNT_BACK',
  SingleRewardFlatAmountBack: 'TYPE_SINGLE_REWARD_FLAT_AMOUNT_BACK',
  SingleRewardSpendXGetY: 'TYPE_SINGLE_REWARD_SPEND_X_GET_Y',
  MultipleRewardPercentAmountBack: 'TYPE_MULTIPLE_REWARD_PERCENT_AMOUNT_BACK',
  MccSingleRewardPercentAmountBack:
    'TYPE_MCC_SINGLE_REWARD_PERCENT_AMOUNT_BACK',
  MccSingleRewardFlatAmountBack: 'TYPE_MCC_SINGLE_REWARD_FLAT_AMOUNT_BACK',
  CustomRewardRedemption: 'TYPE_CUSTOM_REWARD_REDEMPTION'
};
