import { post } from './post';
import { postOfferTransaction } from './post-offer-transaction';
import { getById } from './get-by-id';
import { getOfferTransactions } from './get-offer-transactions';
import { getOffersById } from './get-offers-by-id';
import { postClientEvent } from './post-click-event';
import { del } from './delete';
import { put } from './put';
import { get } from './get';
import { getRedemptions } from './get-redemptions';

const customerAdapter = {
  post,
  get,
  getById,
  getOffersById,
  del,
  postClientEvent,
  getOfferTransactions,
  put,
  postOfferTransaction,
  getRedemptions
};

export { customerAdapter };
