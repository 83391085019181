import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth } from '@/context-providers/auth';

import { InstitutionGrid } from '@components/Institution/InstitutionGrid/InstitutionGrid';
import { InstitutionGridHeader } from '@components/Institution/InstitutionGrid/InstitutionGridHeader';

import { useApiQuery } from '@hooks/use-api-query';

import type { Institution } from '@models/institution';

import { institutionService } from '@/services/institution';

const InstitutionList = () => {
  const { isCdlxUser, userProfile } = useAuth();
  const [t] = useTranslation();

  const { data, isLoading, isError } = useApiQuery(institutionService.get());

  const [institutionTextFilter, setInstitutionTextFilter] =
    useState<string>('');
  const [institutionActiveFilter, setInstitutionActiveFilter] =
    useState<boolean>(true);

  const handleFilterTextChange = (filter: string) => {
    setInstitutionTextFilter(filter);
  };

  const handleFilterActiveChange = (filter: boolean) => {
    setInstitutionActiveFilter(filter);
  };

  const filteredInstitutions: Institution[] = useMemo(() => {
    if (!data?.institutions || !Array.isArray(data.institutions)) {
      return [];
    }

    const filteredByText = data.institutions.filter(
      (institution) =>
        institution.name
          .toLowerCase()
          .includes(institutionTextFilter.toLowerCase()) ||
        institution.codeName
          .toLowerCase()
          .includes(institutionTextFilter.toLowerCase()) ||
        institution.id.toString().includes(institutionTextFilter)
    );

    const testInstitutions = filteredByText.filter(
      (institution) => institution.isTest
    );

    const userInstitutions = isCdlxUser
      ? testInstitutions
      : testInstitutions.filter((institution) =>
          userProfile.institutionId.includes(`${institution.id}`)
        );

    const filteredActiveInstitutions = institutionActiveFilter
      ? userInstitutions.filter((institution) => institution.isActive)
      : userInstitutions;

    return filteredActiveInstitutions;
  }, [data?.institutions, institutionTextFilter, institutionActiveFilter]);

  return (
    <div>
      <div className='mb-10 border-b-2 border-b-gray-rules py-10'>
        <InstitutionGridHeader
          onFilterText={handleFilterTextChange}
          onFilterActive={handleFilterActiveChange}
          hideInactiveValue={institutionActiveFilter}
        />
      </div>
      {isError ? (
        <h2 className='text-xl font-bold'>
          {t('institution.couldNotGetInstitution', { count: 2 })}
        </h2>
      ) : (
        <InstitutionGrid
          institutions={filteredInstitutions}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};

export { InstitutionList };
