import { useTranslation } from 'react-i18next';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';

import {
  Badge,
  ServerSideTable,
  SingleCheckbox,
  tableColumnDefs,
  TextInput
} from '@library/components';
import { Customer } from '@models/customer';

interface TargetCustomerListProps {
  isLoading: boolean;
  customers: Customer[];
  totalCustomers: number;
  pagination: {
    pageIndex: number;
    pageSize: number;
  };
  selectedCustomers: Customer[];
  customerFilterText: string;
  onFilterCustomers: (searchText) => void;
  onSelectCustomerChange: (arg) => void;
  onPaginationChange: (arg) => void;
}

const isCustomerIncluded = (selectedCustomers, customer) => {
  if (selectedCustomers.length === 0) {
    return false;
  }
  return selectedCustomers.some(
    (selected) => selected.customerId === customer.customerId
  );
};

const TargetCustomerList = ({
  customers,
  totalCustomers,
  isLoading,
  pagination,
  selectedCustomers,
  customerFilterText,
  onFilterCustomers,
  onSelectCustomerChange,
  onPaginationChange
}: TargetCustomerListProps) => {
  const [t] = useTranslation();

  const columns = [
    tableColumnDefs.custom<Customer>({
      header: '',
      id: 'id2',
      accessorKey: 'id',
      enableSorting: false,
      cell({ row }) {
        return (
          <>
            <div className='mr-2'>
              <SingleCheckbox
                id={`${row.original.customerId}`}
                checked={isCustomerIncluded(selectedCustomers, row.original)}
                disabled={row.original.customerId === null}
                onSelectedItemChange={(value) =>
                  onSelectCustomerChange({
                    value,
                    customer: row.original
                  })
                }
              />
            </div>
          </>
        );
      }
    }),
    tableColumnDefs.custom<Customer>({
      header: `${t('label.sourceCustomerId')}`,
      accessorKey: 'sourceCustomerId',
      enableSorting: false,
      cell({ row }) {
        const isCustomerInVertica = !row.original.customerId;
        return (
          <>
            <div>
              <div className='relative'>{row.original.sourceCustomerId}</div>
              {isCustomerInVertica && (
                <div className='inline-block'>
                  <Badge variant='variant.primary' text='In progress' />
                </div>
              )}
            </div>
          </>
        );
      }
    }),
    tableColumnDefs.custom<Customer>({
      header: `${t('label.enrollmentStatus')}`,
      accessorKey: 'enrollmentOptInStatus',
      enableSorting: false,
      cell({ row }) {
        return (
          <>
            <div className='relative'>
              {row.original.enrollmentOptInStatus === 'ACTIVE'
                ? t('label.optedIn')
                : t('label.optedOut')}
            </div>
          </>
        );
      }
    }),
    tableColumnDefs.custom<Customer>({
      header: `${t('label.targetedOffers')}`,
      accessorKey: 'targetedOffers',
      enableSorting: false,
      cell({ row }) {
        return <>{row.original.targetedOffers}</>;
      }
    }),
    tableColumnDefs.custom<Customer>({
      header: `${t('label.servedOffers')}`,
      accessorKey: 'servedOffers',
      enableSorting: false,
      cell({ row }) {
        return <>{row.original.servedOffers}</>;
      }
    }),
    tableColumnDefs.custom<Customer>({
      header: `${t('label.activatedOffers')}`,
      accessorKey: 'activatedOffers',
      enableSorting: false,
      cell({ row }) {
        return <>{row.original.activatedOffers}</>;
      }
    }),
    tableColumnDefs.custom<Customer>({
      header: `${t('label.redeemedOffers')}`,
      accessorKey: 'redeemedOffers',
      enableSorting: false,
      cell({ row }) {
        return <>{row.original.redeemedOffers}</>;
      }
    })
  ];

  const handleCustomerFilterInput = (searchText) => {
    onFilterCustomers(searchText);
  };

  return (
    <div>
      <div className='mb-5 w-128'>
        <TextInput
          value={customerFilterText}
          className='flex-1'
          onChange={handleCustomerFilterInput}
          decorator={<MagnifyingGlassIcon />}
          size='size.md'
          placeholder={`${t('label.searchCustomer')}`}
          disabled={isLoading}
        />
      </div>
      <ServerSideTable
        id='customers-list'
        borderless={false}
        data={customers || []}
        loading={isLoading}
        columns={columns}
        totalRows={totalCustomers}
        pagination={pagination}
        onPaginationChange={onPaginationChange}
        pageSizeOptions={[5, 10, 20]}
      />
    </div>
  );
};

export { TargetCustomerList };
