import invariant from 'tiny-invariant';
import { ServiceData, ServiceQuery } from '../service';

import { customerAdapter } from '@adapters/customer';

import { GetCustomerAdRedemptionsResponse } from '@models/customer';

interface GetByIdData extends ServiceData {
  redemptions: GetCustomerAdRedemptionsResponse;
}

interface GetByIdParams {
  sourceCustomerId: string;
}

const getRedemptions: ServiceQuery<GetByIdData, GetByIdParams> = async (
  context
) => {
  const { queryKey, signal } = context;
  const [{ sourceCustomerId }] = queryKey;

  invariant(sourceCustomerId, 'sourceCustomerId is required');

  const { data } = await customerAdapter.getRedemptions(
    { sourceCustomerId },
    { signal }
  );

  return { redemptions: data };
};

export type { GetByIdData, GetByIdParams };
export { getRedemptions };
