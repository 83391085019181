import { useEffect, useMemo, useState } from 'react';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

import { EditCustomerModalContent } from './EditCustomerModalContent';
import { IconButton, Modal, Tooltip, useToasts } from '@/library/components';

import { AdRedemption } from '@models/customer';

import { CustomerProvider } from '@/context-providers/customer';

import { useApiQuery } from '@hooks/use-api-query';

import { Offer } from '@models/campaign';

import { customerService } from '@services/customer';

const EditCustomerModal = ({
  isCustomerInVertica,
  sourceCustomerId,
  isLoading
}: {
  sourceCustomerId: string;
  isCustomerInVertica: boolean;
  isLoading: boolean;
}) => {
  const [t] = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const { sendToast } = useToasts();

  useEffect(() => {
    if (!isCustomerInVertica && isOpen) {
      setIsOpen(false);

      sendToast({
        variant: 'variant.info',
        content: 'The customer is being created. This might take a few hours'
      });
    }
  }, [isCustomerInVertica, isOpen]);

  const {
    data: offersResponse,
    isLoading: isLoadingOffers,
    isError: isErrorOffers,
    refetch: refetchOffers,
    isRefetching: isRefetchingOffers
  } = useApiQuery(
    customerService.getOffersById({
      params: { sourceCustomerId },
      enabled: isCustomerInVertica && isOpen && sourceCustomerId.length > 0
    })
  );

  const {
    data: redemptionResponse,
    isLoading: isLoadingRedemptions,
    isError: isErrorRedemptions
  } = useApiQuery(
    customerService.getRedemptions({
      params: { sourceCustomerId },
      enabled: isCustomerInVertica && isOpen && sourceCustomerId.length > 0
    })
  );

  const customerRedemptions = useMemo(() => {
    if (
      !isLoadingRedemptions &&
      redemptionResponse?.redemptions?.redemptions.length
    ) {
      return redemptionResponse.redemptions.redemptions;
    }
    return [] as AdRedemption[];
  }, [redemptionResponse, isLoadingRedemptions]);

  const offers = useMemo(
    () => offersResponse?.offers || ([] as Offer[]),
    [offersResponse]
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      trigger={
        <div>
          <Tooltip content={t('label.editCustomer')} placement='placement.top'>
            <IconButton
              onClick={() => setIsOpen(true)}
              size='size.md'
              variant='variant.primary'
              disabled={isLoading}
            >
              <PencilSquareIcon />
            </IconButton>
          </Tooltip>
        </div>
      }
      header={
        <div className='p-4'>
          <span className='text-2xl'>{t('label.editCustomer')}</span>
        </div>
      }
      content={
        <CustomerProvider
          isCustomerInVertica={isCustomerInVertica}
          sourceCustomerId={sourceCustomerId}
          isEditCustomerModalOpen={isOpen}
          setIsEditCustomerModalOpen={setIsOpen}
          refetchOffers={refetchOffers}
          isRefetchingOffers={isRefetchingOffers}
          customerRedemptions={customerRedemptions}
          isErrorRedemptions={isErrorRedemptions}
        >
          <EditCustomerModalContent
            offers={offers}
            isErrorOffer={isErrorOffers}
            isLoadingOffer={isLoadingOffers}
          />
        </CustomerProvider>
      }
      maxWidth='maxWidth.large'
    />
  );
};

export { EditCustomerModal };
